import React, { useState, useRef } from "react";
import styles from "./index.module.scss";
import cn from "classnames";
import { padNum } from "helpers/string";

const AccordionRow = ({ item = {}, i }) => {
  const [height, setHeight] = useState(0);
  const isActive = height !== 0;
  const ref = useRef();

  const { question, answer } = item;

  const toggle = () => {
    const newHeight = height === 0 ? ref.current.scrollHeight : 0;
    setHeight(newHeight);
  };

  return (
    <div className={cn(styles.item)} onClick={toggle}>
      <div className={styles.row}>
        <div className={styles.row__number}>{padNum(i, 2)}</div>
        <div className={styles.row__title}>{question}</div>
        <div className={cn(styles.row__arrow, isActive && styles["row__arrow--active"])}>
          <svg viewBox="0 0 100 100" fill="#333333" xmlns="http://www.w3.org/2000/svg">
            <path d="M20 100V90H82.95L0 7.05L7.05 0L90 82.95V20H100V100H20Z"></path>
          </svg>
        </div>
      </div>
      <div className={styles.row}>
        <div style={{ height: height + "px" }} ref={ref} className={styles.row__description}>
          <div className={styles.row__description__inner}>{answer}</div>
        </div>
      </div>
    </div>
  );
};

const Accordion = ({ items = [] }) => {
  return (
    <div className={styles.accordion}>
      {items.map((item, i) => {
        return <AccordionRow i={i + 1} item={item} />;
      })}
    </div>
  );
};

export default Accordion;
