const fragment = `
  #extension GL_EXT_shader_texture_lod : enable
  #extension GL_OES_standard_derivatives : enable

  precision highp float;

  varying vec2 vUv;
  // uniform float uOpacity;
  uniform vec2 uResolution;
  uniform float uAsciiSize;
  uniform float uDarkMode;

  uniform sampler2D tFlow;
  uniform sampler2D tChar1;
  uniform sampler2D tChar2;
  uniform sampler2D tMask;

  float random (in vec2 st) {
    return fract(sin(dot(st.xy,
                         vec2(12.9898,78.233)))
                 * 43758.5453123);
  } 

  float getMSDFText(vec2 coord, bool isEven) {
    float charSlot = float(isEven);


    vec3 tex = mix(texture2D(tChar1, coord), texture2D(tChar2, coord), charSlot).rgb;
    float signedDist = max(min(tex.r, tex.g), min(max(tex.r, tex.g), tex.b)) - 0.5;
    float d = fwidth(signedDist);
    float alpha = smoothstep(-d, d, signedDist);
    return step(0.01, alpha) * alpha * .4;
  }

  float avgColor(vec3 color) {
    return (color.r + color.g + color.b) / 3.;
  }

  void main() {
    // vec2 uv = vUv;

    
    
    vec2 cell = floor(gl_FragCoord.xy / uAsciiSize);
    vec2 cellUv = (cell * uAsciiSize) / uResolution.xy;
    
    vec3 flow = texture2D(tFlow, cellUv).rgb;

    vec3 nearestColor = texture2D(tMask, cellUv).rgb;



    float mask = step(.5, avgColor(nearestColor)) - step(.1, flow.z);

    bool isEven = mod(cell.x, 2.0) == 0.;



    vec2 asciiUv = mod(gl_FragCoord.xy, uAsciiSize) / uAsciiSize;

    vec3 asciiColor = mix(vec3(0.), vec3(.6), uDarkMode);
    
    vec3 background = mix(vec3(1.), vec3(0.), uDarkMode);
    vec3 ascii = mix(background, asciiColor, getMSDFText(asciiUv, isEven));

    vec3 finalColor = mix(ascii, background, mask);


    gl_FragColor.rgb = finalColor;
    gl_FragColor.a = 1.;

    // gl_FragColor.rgb = nearestColor;
  }
`;

const vertex = `
  attribute vec2 uv;
  attribute vec3 position;
  varying vec2 vUv;

  uniform mat4 modelViewMatrix;
  uniform mat4 projectionMatrix;

  void main() {
    vUv = uv;
    gl_Position = vec4(position.xy, 0., 1.);
  }
`;

export { vertex, fragment };
