import React, { useState, useContext } from "react";
import { message } from "antd";
import { post } from "../../helpers/ajax";
import styles from "./index.module.scss";
import AppStateContext, { BACKEND_KEY } from "contexts/AppStateContext";
import { useHistory } from "react-router-dom";
import { useCookies } from 'react-cookie';

const Auth = props => {
  const [appState, setAppState, setProp] = useContext(AppStateContext);
  const [cookies, setCookie] = useCookies(['key']);
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const onClick = async () => {
    setLoading(true);
    if (!props.address) {
      props.loadWeb3Modal();
    }
    try {
      const msgToSign = "Logging in to Gulliver.chat. Please sign this message to verify you are the owner of this wallet. \n";
      let currentLoader = setTimeout(() => {
        setLoading(false);
      }, 4000);
      let message = msgToSign + props.address;
      let sig = await props.injectedProvider.send("personal_sign", [message, props.address]);
      clearTimeout(currentLoader);
      currentLoader = setTimeout(() => {
        setLoading(false);
      }, 4000);
      const res = await post("auth", {
        address: props.address,
        message: message,
        signature: sig,
      });
      clearTimeout(currentLoader);
      setLoading(false);
      if (res.data) {
        setAppState({
          ...appState,
          [BACKEND_KEY]: res.data,
          authorized: true,
        });
        setCookie('key', res.data, { path: '/', secure: true, sameSite: "strict" });
        // TODO: redirect to /chat here?
        history.push("/chat");
      }
    } catch (e) {
      console.log(e);
      // message.error(" Sorry, the server is overloaded or experienced an error...");
    }
  };

  const label = props.address ?
      loading ? "Loading.." : "Start Chatting"
    : "Connect Wallet";

  return (
    <>
      <button className={props.address ? styles.button : styles.button_connect} onClick={onClick}>
        {label}
      </button>
    </>
  );
};

export default Auth;
