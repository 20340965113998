import { PropertySafetyFilled } from "@ant-design/icons";
import React, { useState, useContext } from "react";
import Frame from "../Frame";
import styles from "./index.module.scss";

export default function Disclaimer(props) {
  return (
    <Frame
      className={styles.disclaimer__frame}
      loaded={props.showDisclaimer}
      title={"FYI"}
      close={() => props.toggle()}
    >
      <div className={styles.disclaimer}>
        <h3>Some things to keep in mind:</h3>
        <ul>
            <li>These chats are not encrypted, so don't share anything private.</li>
            <li>We reserve the right to revoke access to the app if usage is stupid and/or dangerous.</li>
            <li>The more interesting you are, the more interesting your Ape will be.</li>
            <li>If your conversation hits a dead end, or you just want to start over, just hit the 'Burn Convo' button. There's no going back from this.</li>
        </ul>
      </div>
    </Frame>
  );
}
