import React, { Component, useContext } from "react";
import Profile from "../components/Profile";
import Disclaimer from "../components/Disclaimer";
import _ from "lodash";
import { post } from "../helpers/ajax";
import Selection from "../components/Selection";
import AppStateContext, { BACKEND_KEY } from "contexts/AppStateContext";
// import FriendsList from "components/FriendsList";

class Chat extends Component {
  static contextType = AppStateContext;
  constructor(props) {
    super(props);
    this.state = {
      sending: false,
      showDisclaimer: true,
    };
    this.toggleDisclaimer = this.toggleDisclaimer.bind(this);
  }

  async burnMessages() {
    const id = this.state.character.chatAIName + "_" + this.state.character.id;
    // this.toggleSelected(); // TODO
    const result = await post("burn", {
      character_name: this.state.character.chatAIName,
      character_id: id,
      opener: this.state.character.chatOpeningMessage,
      key: this.state.backendKey, // TODO
      address: this.props.address,
    });
  }

  toggleSending() {
    this.setState({
      sending: !this.state.sending,
    });
  }
  
  toggleDisclaimer() {
    this.setState({
      showDisclaimer: false,
    });
  }

  setTransferToAddresses(address) {
    this.setState({
      transferToAddress: address,
    });
  }

  render() {
    const app = (
      <>
        {/* <FriendsList /> */}

        <Selection yourCollectibles={this.props.yourCollectibles}/>
        <Disclaimer 
          showDisclaimer={this.state.showDisclaimer} 
          toggle={this.toggleDisclaimer} 
        />
        <Profile
          // address={this.props.address}
          burnMessages={this.burnMessages}
          toggleSending={this.toggleSending}
          {...this.props}
        />
      </>
    );
    return <main>{app}</main>;
  }
}

export default Chat;
