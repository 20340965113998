import React, { useEffect, useState } from "react";
import styles from "./index.module.scss";
import uniforms from "../Landing/uniforms";
import gsap from "gsap";

const THEME_KEY = "theme";
const THEME_LIGHT = "light";
const THEME_DARK = "dark";

const Moon = () => {
  return (
    <svg width="10" height="11" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="6" width="1" height="1" />
      <rect x="6" y="1" width="1" height="1" />
      <rect x="7" y="1" width="1" height="1" />
      <rect x="7" y="2" width="1" height="1" />
      <rect x="6" y="3" width="1" height="1" />
      <rect x="7" y="3" width="1" height="1" />
      <rect x="8" y="2" width="1" height="1" />
      <rect x="8" y="3" width="1" height="1" />
      <rect x="8" y="4" width="1" height="1" />
      <rect x="8" y="7" width="1" height="1" />
      <rect x="7" y="7" width="1" height="1" />
      <rect x="7" y="6" width="1" height="1" />
      <rect x="7" y="5" width="1" height="1" />
      <rect x="4" y="8" width="1" height="1" />
      <rect x="5" y="8" width="1" height="1" />
      <rect x="5" y="7" width="1" height="1" />
      <rect x="6" y="7" width="1" height="1" />
      <rect x="6" y="6" width="1" height="1" />
      <rect x="6" y="8" width="1" height="1" />
      <rect x="5" y="9" width="1" height="1" />
      <rect width="1" height="1" transform="matrix(1 0 0 -1 4 10)" />
      <rect width="1" height="1" transform="matrix(1 0 0 -1 3 9)" />
      <rect width="1" height="1" transform="matrix(1 0 0 -1 2 8)" />
      <rect width="1" height="1" transform="matrix(1 0 0 -1 1 8)" />
      <rect width="1" height="1" transform="matrix(1 0 0 -1 0 7)" />
      <rect width="1" height="1" transform="matrix(1 0 0 -1 0 8)" />
      <rect width="1" height="1" transform="matrix(1 0 0 -1 0 9)" />
      <rect width="1" height="1" transform="matrix(1 0 0 -1 1 9)" />
      <rect width="1" height="1" transform="matrix(1 0 0 -1 2 9)" />
      <rect width="1" height="1" transform="matrix(1 0 0 -1 1 10)" />
      <rect width="1" height="1" transform="matrix(1 0 0 -1 2 10)" />
      <rect width="1" height="1" transform="matrix(1 0 0 -1 3 10)" />
      <rect width="1" height="1" transform="matrix(1 0 0 -1 2 11)" />
      <rect width="1" height="1" transform="matrix(1 0 0 -1 3 11)" />
      <rect width="1" height="1" transform="matrix(1 0 0 -1 4 11)" />
      <rect width="1" height="1" transform="matrix(1 0 0 -1 5 11)" />
      <rect width="1" height="1" transform="matrix(1 0 0 -1 7 10)" />
      <rect width="1" height="1" transform="matrix(1 0 0 -1 8 9)" />
      <rect width="1" height="1" transform="matrix(1 0 0 -1 7 9)" />
      <rect width="1" height="1" transform="matrix(1 0 0 -1 9 5)" />
      <rect width="1" height="1" transform="matrix(1 0 0 -1 9 6)" />
      <rect width="1" height="1" transform="matrix(1 0 0 -1 8 6)" />
      <rect width="1" height="1" transform="matrix(1 0 0 -1 8 7)" />
      <rect width="1" height="1" transform="matrix(1 0 0 -1 6 10)" />
      <rect x="7" y="4" width="1" height="1" />
      <rect width="1" height="1" transform="matrix(1 0 0 -1 5 1)" />
      <rect width="1" height="1" transform="matrix(1 0 0 -1 5 2)" />
      <rect width="1" height="1" transform="matrix(1 0 0 -1 4 1)" />
      <rect width="1" height="1" transform="matrix(1 0 0 -1 6 3)" />
    </svg>
  );
};

const Sun = () => {
  return (
    <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="7" y="8" width="1" height="1" />
      <rect x="6" y="8" width="1" height="1" />
      <rect x="6" y="5" width="1" height="1" />
      <rect x="6" y="4" width="1" height="1" />
      <rect x="6" y="6" width="1" height="1" />
      <rect x="6" y="7" width="1" height="1" />
      <rect x="5" y="6" width="1" height="1" />
      <rect x="5" y="7" width="1" height="1" />
      <rect x="4" y="8" width="1" height="1" />
      <rect x="5" y="8" width="1" height="1" />
      <rect x="4" y="5" width="1" height="1" />
      <rect x="4" y="4" width="1" height="1" />
      <rect x="4" y="6" width="1" height="1" />
      <rect x="4" y="7" width="1" height="1" />
      <rect x="5" y="5" width="1" height="1" />
      <rect x="5" y="4" width="1" height="1" />
      <rect x="6" y="3" width="1" height="1" />
      <rect x="9" y="5" width="1" height="1" />
      <rect x="9" y="5" width="1" height="1" />
      <rect x="8" y="5" width="1" height="1" />
      <rect x="8" y="4" width="1" height="1" />
      <rect x="9" y="6" width="1" height="1" />
      <rect x="9" y="7" width="1" height="1" />
      <rect x="9" y="6" width="1" height="1" />
      <rect x="9" y="7" width="1" height="1" />
      <rect x="8" y="6" width="1" height="1" />
      <rect x="8" y="7" width="1" height="1" />
      <rect x="8" y="8" width="1" height="1" />
      <rect x="3" y="5" width="1" height="1" />
      <rect x="3" y="6" width="1" height="1" />
      <rect x="3" y="7" width="1" height="1" />
      <rect x="5" y="3" width="1" height="1" />
      <rect x="6" y="1" width="1" height="1" />
      <rect x="2.5" y="2.5" width="1" height="1" />
      <rect x="1.5" y="1.5" width="1" height="1" />
      <rect x="6" width="1" height="1" />
      <rect x="9.5" y="2.5" width="1" height="1" />
      <rect x="1" y="6" width="1" height="1" />
      <rect y="6" width="1" height="1" />
      <rect x="2.5" y="9.5" width="1" height="1" />
      <rect x="1.5" y="10.5" width="1" height="1" />
      <rect x="6" y="11" width="1" height="1" />
      <rect x="6" y="12" width="1" height="1" />
      <rect x="11" y="6" width="1" height="1" />
      <rect x="9.5" y="9.5" width="1" height="1" />
      <rect x="10.5" y="10.5" width="1" height="1" />
      <rect x="10.5" y="1.5" width="1" height="1" />
      <rect x="12" y="6" width="1" height="1" />
      <rect x="7" y="3" width="1" height="1" />
      <rect x="5" y="9" width="1" height="1" />
      <rect width="1" height="1" transform="matrix(1 0 0 -1 7 10)" />
      <rect width="1" height="1" transform="matrix(1 0 0 -1 6 10)" />
      <rect width="1" height="1" transform="matrix(1 0 0 -1 7 6)" />
      <rect width="1" height="1" transform="matrix(1 0 0 -1 7 5)" />
      <rect width="1" height="1" transform="matrix(1 0 0 -1 7 7)" />
      <rect width="1" height="1" transform="matrix(1 0 0 -1 7 8)" />
    </svg>
  );
};

const ThemeToggler = () => {
  const [isDarkMode, setIsDarkMode] = useState(() => {
    let ret = window.localStorage.getItem(THEME_KEY) === THEME_DARK;
    if (ret === null) {
      ret = window.matchMedia && window.matchMedia("(prefers-color-scheme: dark)").matches ? THEME_DARK : THEME_LIGHT;
    }

    return ret;
  });

  useEffect(() => {
    const themeName = isDarkMode ? THEME_DARK : THEME_LIGHT;
    localStorage.setItem(THEME_KEY, themeName);
    document.documentElement.className = themeName;
    gsap.to(uniforms.uDarkMode, { value: isDarkMode ? 1 : 0 });
  }, [isDarkMode]);

  useEffect(() => {
    window.matchMedia("(prefers-color-scheme: dark)").addEventListener("change", e => {
      const isDarkMode = e.matches ? true : false;
      setIsDarkMode(isDarkMode);
    });
  }, []);

  const toggle = () => {
    setIsDarkMode(!isDarkMode);
  };
  return (
    <button className={styles.button} onClick={toggle}>
      {isDarkMode ? <Moon /> : <Sun />}
    </button>
  );
};

export default ThemeToggler;
