import React, { useState, useContext, useEffect } from "react";
import Frame from "../Frame";
import styles from "./index.module.scss";
import cn from "classnames";
import { useMemo } from "react";
import { padNum } from "../../helpers/string";
import AppStateContext from "contexts/AppStateContext";
import { CHARACTER_KEY } from "components/Profile";

const ViewToggle = ({ isGridView, onToggle }) => {
  return (
    <div className={cn(styles.icon, isGridView && styles["icon--grid"])} onClick={onToggle}>
      <div className={styles.icon__bar}></div>
      <div className={styles.icon__bar}></div>
      <div className={styles.icon__bar}></div>
    </div>
  );
};

const VIEW_KEY = "view";
const VIEW_GRID = "grid";
const VIEW_LIST = "list";

const Grid = ({ data, setSelected }) => {
  return (
    <div className={styles.library__grid}>
      {data.map((c, i) => {
        const { chatAIName, image } = c;
        return (
          <div className={styles.character} onClick={() => setSelected(c)}>
            <div className={styles.character__row}>
              <img className={styles.character__image} src={image} />
              <div className={styles.character__arrow}>🡽</div>
            </div>

            <div className={styles.character__column}>
              <div className={styles.character__number}>#{padNum(c.id)}</div>
              <div className={styles.character__name}>{chatAIName}</div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

const List = ({ data, setSelected }) => {
  return (
    <div className={styles.library__list}>
      {data.map((c, i) => {
        const { character_name, image } = c;
        return (
          <div className={styles.character} onClick={() => setSelected(c)}>
            <img className={styles.character__image} src={image} />
            <span></span>
            {/* <div className={styles.character__number}>#{padNum(c.id)}</div> */}
            <div className={styles.character__name}>{character_name}</div>
            <div className={styles.character__arrow}>🡽</div>
          </div>
        );
      })}
    </div>
  );
};

const FILTER_KEY = "filter";
const FILTER_ALPHABETICAL = "chatAIName";
const FILTER_NUMBER = "id";

const sortContent = (arr, key) => {
  if (key === FILTER_NUMBER) {
    return arr.sort((a, b) => {
      return a[key] - b[key];
    });
  } else {
    return arr.sort((a, b) => {
      let x = a[key];
      let y = b[key];

      if (typeof x === "string" && typeof y === "string") {
        x = x.toUpperCase();
        y = y.toUpperCase();
      }

      return x < y ? -1 : x > y ? 1 : 0;
    });
  }
};

const Selection = props => {
  const { yourCollectibles = [] } = props;
  const [appState, _, setProp] = useContext(AppStateContext);
  const [content, setContent] = useState();
  const [isGridView, setIsGridView] = useState(() => {
    let ret = window.localStorage.getItem(VIEW_KEY) === VIEW_GRID;

    return ret === null ? true : ret;
  });

  const [currentFilter, setCurrentFilter] = useState(() => {
    let ret = window.localStorage.getItem(FILTER_KEY);

    return ret === null ? FILTER_ALPHABETICAL : ret;
  });

  const setSelected = nft => {
    setProp(CHARACTER_KEY, nft);
  };

  const onViewToggle = () => {
    const newVal = isGridView ? VIEW_LIST : VIEW_GRID;
    window.localStorage.setItem(VIEW_KEY, newVal);
    setIsGridView(!isGridView);
  };

  useEffect(() => {
    // const sortedData = sortContent(yourCollectibles, currentFilter);
    setContent(isGridView ? (
      <Grid data={yourCollectibles} setSelected={setSelected} />
    ) : (
      <List data={yourCollectibles} setSelected={setSelected} />
    ));
  }, [yourCollectibles]);

  const onFilterChange = e => {
    const val = e.target.value;
    setCurrentFilter(val);
    window.localStorage.setItem(FILTER_KEY, val);
  };

  return (
    <Frame title="Friends List" loaded={true} className={styles.library}>
      <div id="selection" className={styles.library__inner}>
        <div className={styles.library__filters}>
          <div>{yourCollectibles.length} Characters</div>
          <div>
            {/* <div>Sort</div> 
            <select onChange={onFilterChange} value={currentFilter}>
              <option value={FILTER_ALPHABETICAL}>Name</option>
              <option value={FILTER_NUMBER}>Number</option>
            </select> */}

            {/* <div>View</div> 
            <ViewToggle isGridView={isGridView} onToggle={onViewToggle} /> */}
          </div>
        </div>
        {yourCollectibles.length === 0 ? "No characters found. (If you have characters that ought to be showing up here, try going back to home and re-entering.)"
        : content}
      </div>
    </Frame>
  );
};

export default Selection;
