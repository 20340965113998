import { post } from "helpers/ajax";
import takeRightWhile from "lodash/takeRightWhile";
import { padNum } from "helpers/string";

export function trimLinesHelper(additional, lines, hardMax) {
  let characterCount = additional;
  const trimmedLines = takeRightWhile(lines, line => {
    characterCount += line.length;
    return characterCount <= hardMax;
  });
  return trimmedLines;
}

function trimApeLines(additional, lines) {
  // 2048 - 300 is upper bound for tokens.
  // We will assume 1 token ~= 4 characters and keep a window of ~500 tokens.
  const maxPromptLength = 500 * 4;
  // Davinci costs 0.06 per 1k tokens, so this is roughly 3 cents per completion at the upper end.
  return trimLinesHelper(additional, lines, maxPromptLength);
}

function trimLines(additional, lines) {
  // 2048 - 300 is upper bound for tokens.
  // We will assume 1 token ~= 4 characters and keep a window of ~1500 tokens.
  const maxPromptLength = 1500 * 4;
  // Davinci costs 0.06 per 1k tokens, so this is roughly 3 cents per completion at the upper end.
  return trimLinesHelper(additional, lines, maxPromptLength);
}

const getPrompt = (character, messages, stops) => {
  var start;
  start = character.chatStart;
  let messagesMinusPlaceholder = messages.shift();
  const additionalPrompt = stops[0];
  const lines = messages.map(m => `${m.author}: ${m.message}\n`);
  const trimmed = trimLines(start.length + additionalPrompt.length, lines);
  const combinedLines = trimmed.join("");

  return start + "\n" + combinedLines + additionalPrompt;
};

const getApePrompt = (messages, stops) => {
  const additionalPrompt = stops[0];
  let messagesMinusPlaceholder = messages.shift();
  const lines = messages.map(m => `${m.author}: ${m.message}\n`);
  const trimmed = trimLines(additionalPrompt.length, lines);
  const combinedLines = trimmed.join("");

  return "\n" + combinedLines + additionalPrompt;
};

const getCompletion = async (character, prompt, stops, lastMessage, address, backendKey) => {
  const { chatAIName, id } = character;
  if (backendKey == null) {
    // means we're doing a preview chat, use the 'stock character' prompt
    // limit chat to 10 messages for a given ip address on backend
    //  const result = await axios.post(this.props.serverUrl + 'preview_completions', {
    //    character_id: id,
    //    prompt: prompt,
    //    stops: stops,
    //    key: null
    //  });
    return "under construction";
  } else {
    const result = await post("completions", {
      character_name: chatAIName,
      character_id: `${chatAIName}_${id}`,
      prompt: prompt,
      stops: stops,
      key: backendKey,
      last_message: lastMessage,
      address: address,
    });
    return result.data;
  }
};

const getApeCompletion = async (character, prompt, stops, lastMessage, address, backendKey) => {
  const { chatAIName, clothingItems, id } = character;
  const result = await post("ape_completions", {
      character_name: chatAIName,
      character_id: `${chatAIName}_${id}`,
      id_num: padNum(id),
      clothing_items: clothingItems,
      prompt: prompt,
      stops: stops,
      key: backendKey,
      last_message: lastMessage,
      address: address,
    });
    return result.data;
};

export const getAIResponse = async (character, messages, address, backendKey) => {
  const { chatAIName, chatUserName } = character;
  const stops = [chatAIName + ":", chatUserName + ":", "\n"];
  const lastMessage = messages[messages.length - 1];
  if (chatAIName === "Ape") {
    const prompt = getApePrompt(messages, stops);
    const result = await getApeCompletion(character, prompt, stops, lastMessage, address, backendKey);
    return result;
  }
  else {
    const prompt = getPrompt(character, messages, stops);
    const result = await getCompletion(character, prompt, stops, lastMessage, address, backendKey);
    return result;
  }
};

export const getMessageHistory = async (character, address, backendKey) => {
  const { chatAIName, id, chatOpeningMessage } = character;

  const result = await post("get_history", {
    character_name: chatAIName,
    character_id: `${chatAIName}_${id}`,
    opener: chatOpeningMessage,
    key: backendKey, // TODO
    address,
  });
  if (!result.data) {
    // this is first start of conversation
    if (chatOpeningMessage) {
      // this is a Gulliver convo
      var start = {
        messages: [
          {
            author: chatAIName,
            message: chatOpeningMessage,
            timestamp: new Date().getTime(),
          },
        ],
      };
      // console.log("putting this in histyory: " + start);
      return start;
    }
    else {
      // this is an Ape convo
      return null;
    }
  } else {
    return result.data;
  }
};

export const burnMessages = async (character, backendKey, address) => {
  const { chatAIName, id, chatOpeningMessage } = character;

  const result = await post("burn", {
    character_name: chatAIName,
    character_id: `${chatAIName}_${id}`,
    opener: chatOpeningMessage,
    key: backendKey,
    address: address,
  });
};
