import {
  Vec2,
  Vec3,
  Mesh,
  Program,
  Transform,
  Plane,
  Color,
  Texture,
  Flowmap,
  Triangle,
  Geometry,
  TextureLoader,
  Renderer,
  Camera,
} from "ogl";
import gsap from "gsap";
import { fragment as bgFrag, vertex as bgVert } from "./bg.glsl.js";

import uniforms from "./uniforms.js";
import * as Bowser from "bowser";

const VIDEO_KEY = "demo";

const isTouchDevice = () => {
  if (typeof window !== "undefined" && navigator) {
    return (
      navigator.userAgent.match(/Android/i) ||
      navigator.userAgent.match(/webOS/i) ||
      navigator.userAgent.match(/iPhone/i) ||
      navigator.userAgent.match(/iPad/i) ||
      navigator.userAgent.match(/iPod/i) ||
      navigator.userAgent.match(/BlackBerry/i) ||
      navigator.userAgent.match(/Windows Phone/i)
    );
  }

  return false;
};

let events = {};
if (isTouchDevice()) {
  events = {
    move: "touchmove",
  };
} else {
  events = {
    move: "mousemove",
  };
}

export const isSafari = () => {
  if (typeof window !== "undefined" && navigator) {
    return Bowser.getParser(navigator.userAgent).getBrowserName() === "Safari";
  }

  return false;
};

export const isChrome = () => {
  if (typeof window !== "undefined" && navigator) {
    return Bowser.getParser(navigator.userAgent).getBrowserName() === "Chrome";
  }

  return false;
};

const getPos = ({ changedTouches, clientX, clientY, target }) => {
  const x = changedTouches ? changedTouches[0].clientX : clientX;
  const y = changedTouches ? changedTouches[0].clientY : clientY;

  return {
    x,
    y,
    target,
  };
};

const disposeObjects = (object, parent) => {
  if (object === null || object === undefined) return;
  if (parent) parent.removeChild(object);
  // if (object.dispose) {
  //   object.dispose();
  // }
  if (object.geometry) {
    object.geometry.remove();
  }
  if (object.program) {
    object.program.remove();
  }

  if (object.children) {
    let i = 0;
    const l = object.children.length;
    while (i < l) {
      disposeObjects(object.children[0], object);
      i++;
    }
  }

  object = null;
};

class Background {
  renderer;
  gl;
  scene;
  camera;
  bg;
  lastTime;
  flowmap;

  constructor(canvas) {
    this.canvas = canvas;
  }

  velocity = new Vec2(0, 0);
  flowmapMouse = new Vec2(0, 0);
  lastPos = new Vec2(0, 0);

  videoTextures = {};
  videos = {};

  init = async cb => {
    this.renderer = new Renderer({
      antialias: false,
      // dpr:
      alpha: true,
      webgl: 1,
      canvas: this.canvas,
    });
    this.renderer.setSize(window.innerWidth, window.innerHeight);

    this.gl = this.renderer.gl;
    this.gl.getExtension("OES_standard_derivatives");
    this.gl.getExtension("EXT_shader_texture_lod");
    // this.gl.clearColor()

    this.scene = new Transform();

    this.camera = new Camera(this.gl, {
      aspect: window.innerWidth / window.innerHeight,
    });

    await this.createBackground();

    window.addEventListener(events.move, this.onMousemove);
    window.addEventListener("resize", this.onResize);
    this.update();

    cb && cb();
  };

  createBackground = async () => {
    const bgGeometry = new Triangle(this.gl);

    const tChar2 = TextureLoader.load(this.gl, {
      src: "/star.png",
      // src: "https://oframe.github.io/ogl/examples/assets/uv.jpg",
      wrapT: this.gl.REPEAT,
      wrapS: this.gl.REPEAT,
      anisotropy: 0,
      minFilter: this.gl.LINEAR,
      magFilter: this.gl.LINEAR,
      generateMipmaps: true,
    });

    const tChar1 = TextureLoader.load(this.gl, {
      src: "/plus.png",
      // src: "https://oframe.github.io/ogl/examples/assets/uv.jpg",
      wrapT: this.gl.REPEAT,
      wrapS: this.gl.REPEAT,
      anisotropy: 0,
      minFilter: this.gl.LINEAR,
      magFilter: this.gl.LINEAR,
      generateMipmaps: true,
    });

    await this.loadVideoTexture(VIDEO_KEY, "/animation.mp4");

    const flowmap = new Flowmap(this.gl, { falloff: 0.2, dissipation: 0.9 });
    flowmap.aspect = this.camera.aspect;

    const bgProgram = new Program(this.gl, {
      vertex: bgVert,
      fragment: bgFrag,
      uniforms: {
        tChar1: {
          value: tChar1,
        },
        tChar2: {
          value: tChar2,
        },
        tMask: {
          value: this.videoTextures[VIDEO_KEY],
        },
        tFlow: flowmap.uniform,
        uResolution: {
          value: new Vec2(window.innerWidth, window.innerHeight),
        },
        uDarkMode: uniforms.uDarkMode,
        uAsciiSize: {
          value: 10,
        },
      },
    });

    const bg = new Mesh(this.gl, {
      geometry: bgGeometry,
      program: bgProgram,
    });

    bg.setParent(this.scene);

    this.flowmap = flowmap;
    this.bg = bg;
    this.videos[VIDEO_KEY].play();
  };

  update = delta => {
    this.raf = requestAnimationFrame(this.update);

    if (!this.velocity.needsUpdate) {
      this.flowmapMouse.set(-1);
      this.velocity.set(0);
    }
    this.velocity.needsUpdate = false;
    this.flowmap.mouse.copy(this.flowmapMouse);
    this.flowmap.velocity.lerp(this.velocity, this.velocity.len ? 0.5 : 0.1);
    this.flowmap.update();

    this.videoTextures[VIDEO_KEY].needsUpdate = true;

    this.renderer.render({ scene: this.scene, camera: this.camera });
  };

  onResize = () => {
    this.renderer.setSize(window.innerWidth, window.innerHeight);
    this.camera.perspective({
      aspect: window.innerWidth / window.innerHeight,
    });

    if (this.bg) {
      disposeObjects(this.bg, this.scene);
      this.createBackground();
    }
  };

  onMousemove = e => {
    const { x, y } = getPos(e);

    const u = x / window.innerWidth;
    const v = 1 - y / window.innerHeight;
    // uniforms.uv.value.set(u ,v);
    this.flowmapMouse.set(u, v);

    if (!this.lastTime) {
      this.lastTime = Date.now();
      this.lastPos.set(x, y);
    }

    const t = Date.now();

    const delta = Math.max(14, t - this.lastTime);
    this.lastTime = t;

    this.velocity.set((x - this.lastPos.x) / delta, (y - this.lastPos.y) / delta);
    this.lastPos.set(x, y);
    this.velocity.needsUpdate = true;
  };

  dispose = () => {
    window.removeEventListener("resize", this.onResize);
    window.removeEventListener(events.move, this.onMousemove);

    cancelAnimationFrame(this.raf);
    disposeObjects(this.bg, this.scene);
  };

  loadVideoTexture = (key, url) => {
    return new Promise(resolve => {
      if (this.videoTextures[key]) {
        return resolve();
      }

      const video = document.createElement("video");
      video.crossOrigin = "anonymous";
      video.width = 1024;
      video.height = 512;
      video.loop = true;
      video.playsInline = true;
      video.autoplay = true;
      video.muted = true;

      const xhr = new XMLHttpRequest();
      xhr.open("GET", url, true);
      xhr.responseType = "arraybuffer";

      xhr.onload = e => {
        const blob = new Blob([e.target.response], { type: "video/mp4" });

        video.muted = true;
        video.currentTime = 0.01;
        const _resolve = () => {
          resolve();
        };

        if (isSafari() || (isTouchDevice() && isChrome())) {
          video.addEventListener("loadedmetadata", _resolve);
        } else {
          video.oncanplay = _resolve();
        }
        video.src = URL.createObjectURL(blob);

        const videoTexture = new Texture(this.gl, {
          generateMipmaps: false,
          width: 1024,
          height: 512,
        });

        videoTexture.image = video;
        videoTexture.needsUpdate = true;

        this.videoTextures[key] = videoTexture;
        this.videos[key] = video;
      };

      xhr.send();
    });
  };
}

export default Background;
