import React, { useState, useContext } from "react";
import MessageList from "../MessageList/index";
import Frame from "../Frame";
import styles from "./index.module.scss";
import cn from "classnames";
import AppStateContext, { BACKEND_KEY } from "contexts/AppStateContext";
import { useCookies } from 'react-cookie';

import { burnMessages } from "helpers/gpt";

const TAB_INFO = "info";
const TAB_CHAT = "chat";
export const CHARACTER_KEY = "character";

export default function Profile({ address }) {
  const [activeTab, setActiveTab] = useState(TAB_INFO);
  const [appState, _, setProp] = useContext(AppStateContext);
  const [cookies] = useCookies(['key']);
  const character = appState[CHARACTER_KEY] || {};
  const { chatAIName, id, character_name, chatOpeningMessage, image, chatUserName } = character;

  const key = (
    cookies.key ? cookies.key : appState[BACKEND_KEY]
  );

  const burn = () => {
    burnMessages(character, key, address);
    setActiveTab(TAB_INFO);
  };

  let content;
  if (activeTab === TAB_CHAT) {
    content = (
      <div className={styles.messenger}>
        <div className={styles.messenger__header}>
          <img className={styles.messenger__image} src={image} />
          <div className={styles.messenger__name}>{character_name}</div>
          <button className={styles.messenger__burnButton} onClick={burn}>Burn Convo</button>
        </div>
        <div className={styles.messenger__messages}>
          <MessageList address={address} backendKey={key} />
        </div>
      </div>
    );
  } else {
    content = (
      <div className={styles.info}>
        <div className={styles.info__header}>
          <img className={styles.info__image} src={image} />
        </div>
        <div className={styles.info__body}>
          <div className={styles.info__name}>
            {/* redundant for apes {chatAIName} */}
            <div className={styles.info__id}>{character_name}</div>
          </div>
          <div className={styles.info__quote}>{chatOpeningMessage}</div>
        </div>
      </div>
    );
  }

  return (
    <Frame
      className={styles.profile__frame}
      loaded={!!appState[CHARACTER_KEY]}
      title={"Character Profile"}
      close={() => setProp(CHARACTER_KEY, null)}
    >
      <div className={styles.profile}>
        <div className={styles.profile__tabs}>
          <div
            onClick={() => setActiveTab(TAB_CHAT)}
            className={cn(styles.profile__tab, activeTab === TAB_CHAT && styles["profile__tab--active"])}
          >
            Chat
          </div>
          <div
            onClick={() => setActiveTab(TAB_INFO)}
            className={cn(styles.profile__tab, activeTab === TAB_INFO && styles["profile__tab--active"])}
          >
            Info
          </div>
        </div>
        {content}
      </div>
    </Frame>
  );
}
