import React from "react";

export const Discord = () => {
  return (
    <a href="https://discord.gg/cY4SyYwPWk" target="_blank" rel="noopener noreferrer">
      <svg strokeWidth="0" viewBox="0 0 448 512" aria-label="Discord Icon" xmlns="http://www.w3.org/2000/svg">
        <path d="M297.216 243.2c0 15.616-11.52 28.416-26.112 28.416-14.336 0-26.112-12.8-26.112-28.416s11.52-28.416 26.112-28.416c14.592 0 26.112 12.8 26.112 28.416zm-119.552-28.416c-14.592 0-26.112 12.8-26.112 28.416s11.776 28.416 26.112 28.416c14.592 0 26.112-12.8 26.112-28.416.256-15.616-11.52-28.416-26.112-28.416zM448 52.736V512c-64.494-56.994-43.868-38.128-118.784-107.776l13.568 47.36H52.48C23.552 451.584 0 428.032 0 398.848V52.736C0 23.552 23.552 0 52.48 0h343.04C424.448 0 448 23.552 448 52.736zm-72.96 242.688c0-82.432-36.864-149.248-36.864-149.248-36.864-27.648-71.936-26.88-71.936-26.88l-3.584 4.096c43.52 13.312 63.744 32.512 63.744 32.512-60.811-33.329-132.244-33.335-191.232-7.424-9.472 4.352-15.104 7.424-15.104 7.424s21.248-20.224 67.328-33.536l-2.56-3.072s-35.072-.768-71.936 26.88c0 0-36.864 66.816-36.864 149.248 0 0 21.504 37.12 78.08 38.912 0 0 9.472-11.52 17.152-21.248-32.512-9.728-44.8-30.208-44.8-30.208 3.766 2.636 9.976 6.053 10.496 6.4 43.21 24.198 104.588 32.126 159.744 8.96 8.96-3.328 18.944-8.192 29.44-15.104 0 0-12.8 20.992-46.336 30.464 7.68 9.728 16.896 20.736 16.896 20.736 56.576-1.792 78.336-38.912 78.336-38.912z"></path>
      </svg>
    </a>
  );
};

export const Twitter = () => {
  return (
    <a href="https://twitter.com/gulliverchat" target="_blank" rel="noopener noreferrer">
      <svg strokeWidth="0" viewBox="0 0 512 512" aria-label="Twitter Icon" xmlns="http://www.w3.org/2000/svg">
        <path d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z"></path>
      </svg>
    </a>
  );
};

export const Medium = () => {
  return (
    <a href="https://medium.com/@gulliverchat" target="_blank" rel="noopener noreferrer">
      <svg viewBox="0 0 1043.63 592.71" aria-label="Medium Icon">
        <g data-name="Layer 2">
          <g data-name="Layer 1">
            <path d="M588.67 296.36c0 163.67-131.78 296.35-294.33 296.35S0 460 0 296.36 131.78 0 294.34 0s294.33 132.69 294.33 296.36M911.56 296.36c0 154.06-65.89 279-147.17 279s-147.17-124.94-147.17-279 65.88-279 147.16-279 147.17 124.9 147.17 279M1043.63 296.36c0 138-23.17 249.94-51.76 249.94s-51.75-111.91-51.75-249.94 23.17-249.94 51.75-249.94 51.76 111.9 51.76 249.94"></path>
          </g>
        </g>
      </svg>
    </a>
  );
};

export const Logo = () => {
  return (
    <svg width="33" height="38" viewBox="0 0 33 38" xmlns="http://www.w3.org/2000/svg">
      <rect x="9.89999" width="9.9" height="6.90909" />
      <rect x="3.29999" y="3.45459" width="6.6" height="6.90909" />
      <rect y="10.3636" width="6.6" height="6.90909" />
      <rect y="17.2727" width="3.3" height="3.45455" />
      <rect x="3.29999" y="20.7273" width="3.3" height="6.9091" />
      <rect x="3.29999" y="27.6364" width="6.6" height="6.90909" />
      <rect y="24.1819" width="3.3" height="3.45454" />
      <rect x="9.89999" y="31.0909" width="9.9" height="6.9091" />
      <rect x="19.8" y="31.0909" width="6.6" height="3.45455" />
      <rect x="26.4" y="27.6364" width="3.3" height="6.90909" />
      <rect x="29.7" y="17.2727" width="3.3" height="10.3636" />
      <rect x="16.5" y="17.2727" width="13.2" height="3.45455" />
      <rect x="26.4" y="20.7273" width="3.3" height="3.45454" />
      <rect x="19.8" width="6.6" height="6.90909" />
      <rect x="26.4" y="3.45459" width="6.6" height="6.90909" />
      <rect x="26.4" y="34.5454" width="3.3" height="3.45455" />
    </svg>
  );
};
