import React, { useState } from "react";
import styles from "./index.module.scss";

// type Props = {
//   addMessage: Function;
// }
export default function Compose(props) {
  const [value, setValue] = useState("");
  const submit = e => {
    e.preventDefault();
    props.addMessage(value);
    setValue("");
  };
  const change = e => {
    setValue(e.target.value);
  };
  return (
    <form onSubmit={submit} className={styles.compose}>
      <input type="text" value={value} className={styles.compose__input} placeholder="Aa" onChange={change} />
      <input type="submit" className={styles.compose__submit} value="Send" />
    </form>
  );
}
