import React, { useContext } from "react";
import styles from "./index.module.scss";
import { Logo } from "../svgs";
import AppStateContext from "../../contexts/AppStateContext";
import { FAQ_KEY } from "../FAQ";
export const MENU_HEIGHT = 56;

const Menu = () => {
  const [appState, _, setProp] = useContext(AppStateContext);

  return (
    <div style={{ height: MENU_HEIGHT }} className={styles.menu}>
      <div className={styles.menu__inner}>
        <div className={styles.menu__logo}>
          <Logo />
        </div>
        <a href="https://gulliver.chat">Home</a>
        {/* <a href="https://gulliver-chat.notion.site/Gulliver-Roadmap-542960cd61a44f2ca8f741f4976ac87a">Roadmap</a>
        <a href="#" onClick={() => setProp(FAQ_KEY, !appState[FAQ_KEY])}>FAQ</a> */}
      </div>
    </div>
  );
};

export default Menu;
