import React, { useRef, useState } from "react";
import Background from "./Background";
import Logo from "../Logo";
import styles from "./index.module.scss";
import cn from "classnames";
import { useEffect } from "react";
import { Discord, Medium, Twitter } from "../svgs";
import gsap from "gsap";
import { TextPlugin } from "gsap/TextPlugin";
import Loader from "../Loader";
import Frame from "../Frame";

gsap.registerPlugin(TextPlugin);

const WORDS = ["mentor", "lover", "friend"];

const Form = () => {
  return (
    <div>
      <div className={styles.form__description}>Be the first to hear when we launch:</div>
      <form id="emailForm" 
            className={styles.form} 
            action="https://chat.us5.list-manage.com/subscribe/post?u=188c124e1ee7800fe5340dcb3&amp;id=dbab9de058" 
            method="post" 
            id="mc-embedded-subscribe-form" 
            name="mc-embedded-subscribe-form" 
            target="_blank">
      	<input className={styles.form__input} 
               type="email"
               name="EMAIL" 
               id="mce-EMAIL" 
               placeholder="email address" 
               required 
               autoComplete="off"
            />
        <div style={{position: "absolute", left: "-5000px"}} aria-hidden="true">
          <input type="text" name="b_188c124e1ee7800fe5340dcb3_dbab9de058" tabIndex="-1" defaultValue="" />
        </div>
        <input className={styles.form__submit} 
               type="submit" 
               value="Subscribe" 
               name="subscribe" 
               id="mc-embedded-subscribe" 
            />
      </form>
    </div>
  );
};

const Intro = props => {
  const textRef = useRef();
  useEffect(() => {
    let idx = 0;
    const interval = setInterval(() => {
      idx = (idx + 1) % WORDS.length;
      gsap.to(textRef.current, {
        duration: 0.6,
        text: {
          value: WORDS[idx],
        },
      });
    }, 2000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <>
      <div className={styles.intro__section}>
        <p className={styles.intro__title}>
          <span className={styles.highlighted}>Meet your</span>
          <br />
          <span className={styles.highlighted}>
            new <span ref={textRef}>friend</span>
          </span>
        </p>
        <p>
          We’re bringing artificial intelligence to NFTs. Welcome to a world where you can own AI companions and talk to
          them anytime, anywhere, about anything you want.
        </p>
        <p>
          Powered by state of the art natural language models, each Gulliver character responds intelligently in real
          time. Each character also has a unique body of knowledge and a personality
          that continues to evolve throughout your conversation.
        </p>
        <p style={{"fontWeight": "bold"}}>
          To give the community a way to experience the magic of conversational NFTs, we’ve opened up gulliver.chat to any Bored Ape Yacht Club member. Simply log in with your wallet, and any Ape you hold will be ready to chat.
        </p>
      </div>
      <div className={styles.intro__section}>
        <Form />
      </div>

      <div className={styles.intro__header}>
        <div>
          <Logo />
        </div>
        <div>
          <div className={styles.intro__socials}>
            <Discord />
            <Twitter />
            <Medium />
          </div>

          {props.auth}
        </div>
      </div>
    </>
  );
};

const Landing = props => {
  const canvasRef = useRef();
  const containerRef = useRef();

  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    const bg = new Background(canvasRef.current);
    bg.init(() => {
      setLoaded(true);
    });

    return () => {
      bg.dispose();
    };
  }, []);

  return (
    <main>
      <div className={cn(styles.preloader, loaded && styles["preloader--loaded"])}>
        <Loader />
      </div>

      <div className={styles.container} ref={containerRef}>
        {/* <Frame title="Demo Video" loaded={loaded} className={styles.demo}>
          <Demo />
        </Frame>

        <Frame title="Characters" loaded={loaded} className={styles.slideshow} delay={"500ms"}>
          <Slideshow items={SLIDESHOW_ITEMS} />
        </Frame> */}
        <Frame className={styles.intro} loaded={loaded} title={"Welcome to Gulliver"}> {/* delay={"1000ms"}> */}
          <Intro {...props} />
        </Frame>
        <canvas ref={canvasRef} className={styles.canvas} />
      </div>
    </main>
  );
};

export default Landing;
