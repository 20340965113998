import React, { useEffect, useRef, useState, useContext } from "react";
import Compose from "../Compose/index";

import "./MessageList.css";
import MessageBuilderService from "../../services/message-builder.jsx";
import TypingIndicator from "../TypingIndicator/index";
import AppStateContext, { BACKEND_KEY } from "contexts/AppStateContext";
import { getAIResponse, getMessageHistory } from "helpers/gpt";
import { CHARACTER_KEY } from "components/Profile";

export default function MessageList({ address, backendKey }) {
  const [appState] = useContext(AppStateContext);
  const character = appState[CHARACTER_KEY] || {};

  const { id, chatUserName, chatAIName, chatOpeningMessage } = character;

  const [loading, setLoading] = useState(false);
  const [messages, setMessages] = useState([
    {
      author: null,
      message: "placeholder",
      timestamp: new Date().getTime(),
    },
  ]);

  useEffect(() => {
    async function fetchHistory() {
      try {
        setLoading(true);
        const history = await getMessageHistory(character, address, backendKey);
        const messages = history["messages"];
        if (messages.length != 0) {
          for (let i = 0; i < messages.length; i++) {
            setMessages(previous => [...previous, messages[i]]);
          }
        } else {
          setMessages([
            {
              author: chatAIName,
              message: chatOpeningMessage,
              timestamp: new Date().getTime(),
            },
          ]);
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    }
    fetchHistory();
  }, []);
  // const chatTitle = "Chat with " + AIChatName;

  // Stays scrolled to bottom.
  const messagesEndRef = useRef(null);
  useEffect(() => {
    if (messagesEndRef) {
      // eslint-disable-next-line no-unused-expressions
      messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  });
  const addMyMessage = async value => {
    const newMessage = {
      author: chatUserName,
      message: value,
      timestamp: new Date().getTime(),
    };

    setMessages(previous => [...previous, newMessage]);
    setLoading(true);
    const withMine = [...messages, newMessage];
    const response = await getAIResponse(character, withMine, address, backendKey);
    const responseMessage = {
      author: chatAIName,
      message: response,
      timestamp: new Date().getTime(),
    };
    setMessages(previous => [...previous, responseMessage]);
    setLoading(false);
  };

  return (
    <div className="message-list">
      <div className="message-list-container">
        {MessageBuilderService.getMessages(messages, chatUserName)}
        {loading && <TypingIndicator />}
        <div ref={messagesEndRef} />
      </div>

      <Compose addMessage={addMyMessage} />
    </div>
  );
}
