import React, { useState } from "react";

const AppStateContext = React.createContext({});

export default AppStateContext;

export const BACKEND_KEY = "backendKey";

export const AppStateProvider = props => {
  const [appState, setAppState] = useState({
    faq: false,
    character: null,
    [BACKEND_KEY]: null,
    authorized: false,
    your_collectibles: [],
  });

  const setProp = (prop, val) => {
    setAppState({
      ...appState,
      [prop]: val,
    });
  };

  return <AppStateContext.Provider value={[appState, setAppState, setProp]}>{props.children}</AppStateContext.Provider>;
};
