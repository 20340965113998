import React from "react";
import styles from "./index.module.scss";

const Logo = () => {
  return (
    <div className={styles.container}>
      <div className={styles.logo}>Gulliver AI</div>
      <div className={styles.version}>v0.1</div>
    </div>
  );
};

export default Logo;
