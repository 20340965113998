import React, { useRef, useEffect } from "react";
import styles from "./index.module.scss";
import cn from "classnames";
import { Draggable } from "gsap/Draggable";
import { MENU_HEIGHT } from "../Menu";
import gsap from "gsap";

gsap.registerPlugin(Draggable);

const Frame = ({ children, className, loaded, title = "", delay = null, close = null }) => {
  const ref = useRef();
  useEffect(() => {
    Draggable.create(ref.current, {
      type: "x, y",
      inertia: true,
      bounds: {
        top: MENU_HEIGHT,
        left: 0,
        width: window.innerWidth,
        height: window.innerHeight - MENU_HEIGHT,
      },
      dragClickables: false,
    });
  }, []);

  return (
    <div
      ref={ref}
      className={cn(styles.frame__container, className)}
      style={{
        pointerEvents: loaded ? "visible" : "none",
      }}
    >
      <div
        className={cn(styles.frame, loaded && styles["frame--loaded"])}
        style={{
          transitionDelay: delay,
        }}
      >
        <div className={styles.frame__bar}>
          {close && (
            <div className={styles.frame__close} onClick={close}>
              ✕
            </div>
          )}
          {title}
        </div>
        {children}
      </div>
    </div>
  );
};

export default Frame;
