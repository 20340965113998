import React, { useContext } from "react";
import Frame from "../Frame";
import AppStateContext from "../../contexts/AppStateContext";
import styles from "./index.module.scss";
import Accordion from "components/Accordion";

export const FAQ_KEY = "faq";

const DATA = [
  {
    question: "Lorem Ipsum",
    answer: "Lorem Ipsum",
  },
  {
    question: "Lorem Ipsum",
    answer: "Lorem Ipsum",
  },
  {
    question: "Lorem Ipsum",
    answer: "Lorem Ipsum",
  },
];

const FAQ = () => {
  const [appState, _, setProp] = useContext(AppStateContext);
  return (
    <Frame loaded={appState[FAQ_KEY]} title={"FAQ"} className={styles.faq} close={() => setProp(FAQ_KEY, false)}>
      <div className={styles.faq__inner}>
        <Accordion items={DATA} />
      </div>
    </Frame>
  );
};

export default FAQ;
