import WalletConnectProvider from "@walletconnect/web3-provider";
import { Alert } from "antd";
import React, { useCallback, useEffect, useState, useContext } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Web3Modal from "web3modal";
import { INFURA_ID, NETWORK, NETWORKS } from "./constants";
import {
  useContractLoader,
  useContractReader,
  useUserSigner,
} from "./hooks";
// import Hints from "./Hints";
import { Chat } from "./views";
import ThemeToggler from "./components/ThemeToggler";
import Menu from "./components/Menu";
import cn from "classnames";
import FAQ from "./components/FAQ";
import AppStateContext, { AppStateProvider } from "./contexts/AppStateContext";
import Landing from "components/Landing";
import Auth from "components/Auth";
import Account from "components/Account";
import { padNum } from "helpers/string";
import { CookiesProvider } from 'react-cookie';

const { ethers } = require("ethers");

const { BufferList } = require("bl");

// https://www.npmjs.com/package/ipfs-http-client
const ipfsAPI = require("ipfs-http-client");

const ipfs = ipfsAPI({ host: "ipfs.infura.io", port: "5001", protocol: "https" });

/// 📡 What chain are your contracts deployed to?
const targetNetwork = NETWORKS.mainnet; // <------- select your target frontend network (localhost, rinkeby, xdai, mainnet)

// 😬 Sorry for all the console logging
const DEBUG = false;
const NETWORKCHECK = false;

const scaffoldEthProvider = navigator.onLine
  ? new ethers.providers.StaticJsonRpcProvider("https://rpc.scaffoldeth.io:48544")
  : null;
const mainnetInfura = navigator.onLine
  ? new ethers.providers.StaticJsonRpcProvider("https://mainnet.infura.io/v3/" + INFURA_ID)
  : null;
// ( ⚠️ Getting "failed to meet quorum" errors? Check your INFURA_ID

// 🏠 Your local provider is usually pointed at your local blockchain
const localProviderUrl = targetNetwork.rpcUrl;
// as you deploy to other networks you can set REACT_APP_PROVIDER=https://dai.poa.network in packages/react-app/.env
const localProviderUrlFromEnv = process.env.REACT_APP_PROVIDER ? process.env.REACT_APP_PROVIDER : localProviderUrl;

const localProvider = new ethers.providers.StaticJsonRpcProvider(localProviderUrlFromEnv);

// 🔭 block explorer URL
const blockExplorer = targetNetwork.blockExplorer;

/*
  Web3 modal helps us "connect" external wallets:
*/
const web3Modal = new Web3Modal({
  // network: "mainnet", // optional
  cacheProvider: true, // optional
  providerOptions: {
    walletconnect: {
      package: WalletConnectProvider, // required
      options: {
        infuraId: INFURA_ID,
      },
    },
  },
});

const logoutOfWeb3Modal = async () => {
  await web3Modal.clearCachedProvider();
  setTimeout(() => {
    window.location.reload();
  }, 1);
};

function App(props) {
  const mainnetProvider = scaffoldEthProvider && scaffoldEthProvider._network ? scaffoldEthProvider : mainnetInfura;

  const [injectedProvider, setInjectedProvider] = useState();
  const [address, setAddress] = useState();
  /* 💵 This hook will get the price of ETH from 🦄 Uniswap: */
  // const price = useExchangePrice(targetNetwork, mainnetProvider);

  /* 🔥 This hook will get the price of Gas from ⛽️ EtherGasStation */
  // const gasPrice = useGasPrice(targetNetwork, "fast");
  // Use your injected provider from 🦊 Metamask or if you don't have it then instantly generate a 🔥 burner wallet.
  const userSigner = useUserSigner(injectedProvider); // , localProvider);

  useEffect(() => {
    async function getAddress() {
      if (userSigner) {
        const newAddress = await userSigner.getAddress();
        setAddress(newAddress);
      }
    }
    getAddress();
  }, [userSigner]);

  // You can warn the user if you would like them to be on a specific network
  const localChainId = localProvider && localProvider._network && localProvider._network.chainId;
  const selectedChainId =
    userSigner && userSigner.provider && userSigner.provider._network && userSigner.provider._network.chainId;

  const readContracts = useContractLoader(localProvider);

  const mainnetContracts = useContractLoader(mainnetProvider);

  // keep track of Gulliver NFT balance
  // const balance = useContractReader(readContracts, "NFTAI", "balanceOf", [address], 30000);
  // keep track of Bored Apes NFT balance
  const apeBalance = useContractReader(mainnetContracts, "APES", "balanceOf", [address], 30000);
  //
  // 🧠 This effect will update yourCollectibles by polling when your balance changes
  //
  const yourBalance = apeBalance && apeBalance.toNumber && apeBalance.toNumber();

  // have disclaimer show on chat page by default
  const [appState, setAppState] = useContext(AppStateContext);

  // helper function to "Get" from IPFS
  // you usually go content.toString() after this...
  const getFromIPFS = async hashToGet => {
    for await (const file of ipfs.get(hashToGet)) {
      if (!file.content) continue;
      const content = new BufferList();
      for await (const chunk of file.content) {
        content.append(chunk);
      }
      return content;
    }
  };

  const updateYourCollectibles = async () => {
    const collectibleUpdate = [];
    for (let tokenIndex = 0; tokenIndex < apeBalance; tokenIndex++) {
      // For Gulliver NFTs:
      //  try {
      //    const tokenId = await readContracts.NFTAI.tokenOfOwnerByIndex(address, tokenIndex);
      //    const tokenURI = await readContracts.NFTAI.tokenURI(tokenId);
      //    const ipfsHash = tokenURI.replace("https://ipfs.io/ipfs/", "");
      //    // const gen = await readContracts.NFTAI.getGeneration(tokenId);
      //    const jsonManifestBuffer = await getFromIPFS(ipfsHash);  
      //    try {
      //      const jsonManifest = JSON.parse(jsonManifestBuffer.toString());
      //      collectibleUpdate.push({ id: tokenId, owner: address, ...jsonManifest }); // uri: tokenURI, generation: gen,
      //    } catch (e) {
      //      console.log(e);
      //    }
      //  } catch (e) {
      //    console.log(e);
      //  }
      // For Apes:
      try {
        const tokenId = await mainnetContracts.APES.tokenOfOwnerByIndex(address, tokenIndex);
        const tokenURI = await mainnetContracts.APES.tokenURI(tokenId);
        const ipfsHash = tokenURI.replace("ipfs://", "");
        const jsonManifestBuffer = await getFromIPFS(ipfsHash); 
        try {
          var jsonManifest = JSON.parse(jsonManifestBuffer.toString());
          var clothingItems = [];
          for (let i = 0; i < jsonManifest["attributes"].length; i++) {
            if (jsonManifest["attributes"][i]["trait_type"] === "Hat" || jsonManifest["attributes"][i]["trait_type"] === "Clothes" || jsonManifest["attributes"][i]["trait_type"] === "Earring") {
              clothingItems.push(jsonManifest["attributes"][i]["value"]);
            }
          }
          jsonManifest["image"] = jsonManifest["image"].replace("ipfs://", "https://ipfs.io/ipfs/");
          collectibleUpdate.push({ id: tokenId, 
            owner: address, 
            character_name: "Bored Ape " + padNum(tokenId),
            chatAIName: "Ape", 
            chatUserName: "Me",
            clothingItems: clothingItems,
            ...jsonManifest });
        } catch (e) {
          console.log(e);
        }
      } catch (e) {
        console.log(e);
      }
    }
    collectibleUpdate.push({ 
      id: address, 
      owner: address,
      chatStart: "This is a conversation between me and South African Billionaire Elon Musk. Elon and I are good friends; we often discuss the future of AI, cryptocurrency, and space exploration. He often causes controversy over what he says on Twitter, but is very polite to me.",
      chatUserName: "Me",
      chatAIName: "Elon Musk",
      character_name: "Elon Musk",
      chatOpeningMessage: "Hey, how’s it going?",
      external_url: "https://gulliver.chat/images/",// <-- this can link to a page for the specific file too
      image: "https://storage.googleapis.com/gulliver-images/elon.png"
    });
    collectibleUpdate.push({ 
      id: address, 
      owner: address,
      chatStart: "The following is an interview with Slovenian philosopher Slajov Zizek. He is here today to talk about his recent film projects, his critiques of 'pure ideology', and his thoughts on the future of capitalism.",
      chatUserName: "Me",
      chatAIName: "Slavoj",
      character_name: "Slavoj Zizek",
      chatOpeningMessage: "Hello, it is *sniffs* a pleasure to be here.",
      external_url: "https://gulliver.chat/images/",// <-- this can link to a page for the specific file too
      image: "https://storage.googleapis.com/gulliver-images/slavoj.png",
    });
    setAppState({
      ...appState,
      your_collectibles: collectibleUpdate,
    });
  };

  useEffect(() => {
    updateYourCollectibles();
  }, [address, yourBalance]);

  let networkDisplay = "";
  if (NETWORKCHECK && localChainId && selectedChainId && localChainId !== selectedChainId) {
    const networkSelected = NETWORK(selectedChainId);
    const networkLocal = NETWORK(localChainId);
    if (selectedChainId === 1337 && localChainId === 31337) {
      networkDisplay = (
        <div style={{ zIndex: 2, position: "absolute", right: 0, top: 60, padding: 16 }}>
          <Alert
            message="⚠️ Wrong Network ID"
            description={
              <div>
                You have <b>chain id 1337</b> for localhost and you need to change it to <b>31337</b> to work with
                HardHat.
                <div>(MetaMask -&gt; Settings -&gt; Networks -&gt; Chain ID -&gt; 31337)</div>
              </div>
            }
            type="error"
            closable={false}
          />
        </div>
      );
    } else {
      networkDisplay = (
        <div style={{ zIndex: 2, position: "absolute", right: 0, top: 60, padding: 16 }}>
          <Alert
            message="⚠️ Wrong Network"
            description={
              <div>
                You have <b>{networkSelected && networkSelected.name}</b> selected and you need to be on{" "}
                <b>{networkLocal && networkLocal.name}</b>.
              </div>
            }
            type="error"
            closable={false}
          />
        </div>
      );
    }
  } else {
    networkDisplay = (
      <div style={{ zIndex: -1, position: "absolute", right: 154, top: 28, padding: 16, color: targetNetwork.color }}>
        {targetNetwork.name}
      </div>
    );
  }

  const loadWeb3Modal = useCallback(async () => {
    const provider = await web3Modal.connect();
    setInjectedProvider(new ethers.providers.Web3Provider(provider));

    provider.on("chainChanged", chainId => {
      console.log(`chain changed to ${chainId}! updating providers`);
      setInjectedProvider(new ethers.providers.Web3Provider(provider));
    });

    provider.on("accountsChanged", () => {
      console.log(`account changed!`);
      setInjectedProvider(new ethers.providers.Web3Provider(provider));
    });

    // Subscribe to session disconnection
    provider.on("disconnect", (code, reason) => {
      console.log(code, reason);
      logoutOfWeb3Modal();
    });
  }, [setInjectedProvider]);

  useEffect(() => {
    if (web3Modal.cachedProvider) {
      loadWeb3Modal();
    }
  }, [loadWeb3Modal]);

  const [route, setRoute] = useState();
  useEffect(() => {
    setRoute(window.location.pathname);
  }, [setRoute]);

  return (
    <div className={cn("App")}>
      <ThemeToggler />
      <Menu 
        auth={
          <Auth
            address={address}
            userSigner={userSigner}
            injectedProvider={injectedProvider}
            authorize={props.onAuthorize}
            loadWeb3Modal={loadWeb3Modal}
          />
        }
      />
      <FAQ />

      <BrowserRouter>
        <Switch>
          <Route exact path="/">
            {networkDisplay}
            <Landing
              auth={
                <Auth
                  address={address}
                  userSigner={userSigner}
                  injectedProvider={injectedProvider}
                  authorize={props.onAuthorize}
                  loadWeb3Modal={loadWeb3Modal}
                />
              }
            />
          </Route>
          <Route path="/chat">
            <Chat
              address={address}
              userSigner={userSigner}
              mainnetProvider={mainnetProvider}
              localProvider={localProvider}
              injectedProvider={injectedProvider}
              readContracts={readContracts}
              yourCollectibles={appState["your_collectibles"]}
            />
            <Account
              address={address}
              userSigner={userSigner}
              mainnetProvider={mainnetProvider}
              web3Modal={web3Modal}
              loadWeb3Modal={loadWeb3Modal}
              logoutOfWeb3Modal={logoutOfWeb3Modal}
              blockExplorer={blockExplorer}
            />
          </Route>
        </Switch>
      </BrowserRouter>
    </div>
  );
}

const AppWrapper = props => {
  return (
    <AppStateProvider>
      <CookiesProvider>
        <App {...props} />
      </CookiesProvider>
    </AppStateProvider>
  );
};

export default AppWrapper;
